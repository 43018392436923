












































































































































import { Component, Vue } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItemsPending } from '@/store/main/actions';
import { readItemCreateError } from '@/store/main/getters';
import { dispatchProposeWalletWithdrawal } from '@/store/main/actions';
import { readConstants } from '@/store/main/getters';
import { readUserProfile, readUserWhitelistAddresses } from '@/store/main/getters';
import { dispatchGetUserProfile, dispatchGetConstants, dispatchGetUserWhitelistAddresses } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';


@Component
export default class Dashboard extends Vue {
  public tokenTypes = ['USDT', 'USDC', 'ETH'];
  public blockchainTypes = ['TRC20', 'BEP20', 'Polygon'];

  public tokenType: string = 'USDT';
  public tokenImgURL: string = require('@/assets/images/token_USDT.png');
  public walletHoldings: number = 0;
  public blockchainType: string = 'TRC20';
  public to_address: string = '';
  public to_address_whitelist: string = '';

  public amount: number = 0;

  public agreements: boolean = false;
  public agreementsText: string = '我已確認此地址支援 TRC20 通道，並知道使用錯誤的通道可能會導致加密貨幣遺失。';

  public otp_key: string = '';

  public valid = false;

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public useMaxToken() {
    this.amount = this.walletHoldings;
  }

  get whitelist_addresses() {
    const address_objects = readUserWhitelistAddresses(this.$store);
    if (address_objects) {
      const out: string[] = [];
      for (var i = 0; i < address_objects.length; i++) {
        out.push(address_objects[i].name +' (' + address_objects[i].address + ')');
      }

      return out;
    }
  }

  public addressRules() {
    return [(v) => !!v || '請填寫提領地址',
            (v) => (v.startsWith('T') || v.startsWith('0x')) || '地址應以 T 或 0x 開頭',]
  }

  public amountRules() {
    let minStr = '金額需大於 0';
    let maxStr = '錢包餘額不足';

    return [
      (v) => !!v || '請決定金額',
      (v) => ( v && v > 0 ) || minStr,
      (v) => ( v && v <= this.walletHoldings ) || maxStr,
    ];
  }

  public agreementRultes() {
    return [v => !!v || '請勾選並同意']
  }

  public publicKeyRules() {
    return [(v) => /^\d{6}$/.test(v) || '請輸入 6 碼驗證碼']
  }

  public changedValue(value) {
    if (this.tokenTypes.includes(this.tokenType) == false) {
      this.tokenType = this.tokenTypes[0];
    }

    // change supported blockchain for each token, and update user wallet holdings
    if (this.tokenType == 'USDT') {
      this.blockchainTypes = ['TRC20', 'BEP20', 'Polygon'];
      this.tokenImgURL = require('@/assets/images/token_USDT.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDT;
      }
    } else if (this.tokenType == 'USDC') {
      this.blockchainTypes = ['BEP20', 'Polygon'];
      this.tokenImgURL = require('@/assets/images/token_USDC.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDC;
      }
    } else if (this.tokenType == 'BUSD') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BUSD.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BUSD;
      }
    } else if (this.tokenType == 'BTC') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BTC.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BTC;
      }
    } else if (this.tokenType == 'ETH') {
      this.blockchainTypes = ['ERC20', 'BEP20'];
      this.tokenImgURL = require('@/assets/images/token_ETH.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_ETH;
      }
    } else if (this.tokenType == 'BNB') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BNB.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BNB;
      }
    } 

    if (this.blockchainTypes.includes(this.blockchainType) == false) {
      this.blockchainType = this.blockchainTypes[0];
    }

    if (this.to_address_whitelist) {
      this.to_address = this.to_address_whitelist.split(')')[0].split('(')[1];
    }

    // if (this.to_address != this.to_address_whitelist.split(')')[0].split('(')[1]) {
    //   this.to_address_whitelist = '';
    // }

    this.agreementsText = '我已確認此地址支援 '+ this.blockchainType + ' 通道，並知道使用錯誤的通道可能會導致加密貨幣遺失。';

    console.log(this.amount);
    console.log(this.tokenType);
    console.log(this.blockchainType);
    console.log(this.to_address);
    console.log("---");
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
      dispatchProposeWalletWithdrawal(this.$store, {token: this.tokenType, amount: this.amount, chain: this.blockchainType, address: this.to_address, otp_key: this.otp_key});
    }
  }

  created() {
    if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDT;
    }
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  async mounted() {
    await dispatchGetConstants(this.$store);
    await dispatchGetUserProfile(this.$store);
    await dispatchGetUserWhitelistAddresses(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}

